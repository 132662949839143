<template>
  <div class="notice">
    <!-- <top-bar :title="'民生小事'" :left="true"></top-bar> -->
    <form action="/">
      <van-search v-model="searchValue" show-action placeholder="请输入提问者姓名搜索" @search="onSearch" @cancel="onCancel"/>
    </form>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#FE5E5E': '' }">提问时间</p>
        </van-col>
        <van-col span="6">
          <p @click="rangeShow = !rangeShow" :style="{color:rangeShow?'#FE5E5E': '' }">显示范围</p>
        </van-col>
        <van-col span="6">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#FE5E5E': '' }">事件类型</p>
        </van-col>
        <van-col span="6">
          <p @click="moreShow = !moreShow">更多<van-icon name="ellipsis" size="20"/></p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <van-popup v-model="beginDateShow" position="bottom" :style="{ height: '40%' }" >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="date" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom" :style="{ height: '40%' }" >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="typeShow" position="bottom" :style="{ height: '40%' }" >
      <van-picker title="事件类型" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
    </van-popup>
    <van-popup v-model="rangeShow" position="bottom" :style="{ height: '40%' }" >
      <van-picker title="显示范围" show-toolbar :columns="rangeList" value-key="label" @confirm="rangeconfirm" @cancel="rangeShow = false"  />
    </van-popup>
    <van-popup v-model="moreShow" position="right" :style="{ width: '90%', height: '100%'}" @open="moreClose">
      <div style="margin-bottom: 50px;">
        <van-cell title="事件类型">
          <span style="color: #FE5E5E">{{moreTypeValue}}</span>
        </van-cell>
        <van-grid  :border="false" :column-num="3">
          <van-grid-item v-for="value in typeList" :key="value.value">
            <van-tag  size="large" @click="typeTagFn(value)" :color="value.label === moreTypeValue? '#ffe1e1':'#f0f2f5'" :text-color="value.label === moreTypeValue?'#FE5E5E':'#000'">{{value.label}}</van-tag>
          </van-grid-item>
        </van-grid>
        <van-cell title="处理状态">
          <span style="color: #FE5E5E">{{moreStatusValue.label}}</span>
        </van-cell>
        <van-grid  :border="false" :column-num="3">
          <van-grid-item v-for="item in statusList" :key="item.value">
            <van-tag  size="large" @click="statusTagFn(item)" :color="item.value == moreStatusValue.value? '#ffe1e1':'#f0f2f5'" :text-color="item.value == moreStatusValue.value?'#FE5E5E':'#000'">{{item.label}}</van-tag>
          </van-grid-item>
        </van-grid>
      </div>
      <van-row style="position: absolute; bottom: 0; left: 0; width: 100%">
        <van-col span="12"><van-button block @click="moreCancel">重置</van-button></van-col>
        <van-col span="12"><van-button type="danger" block @click="moreConfirm">确定</van-button></van-col>
      </van-row>
    </van-popup>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有{{totalCount}}条数据</div>
        <van-cell v-for="item in dataList" :key="item.id"  @click="getInfo(item.id,item.status)">
          <div class="item">
            <van-cell-group :border="false">
              <van-cell :title="item.name + ' | ' + (item.createDate.split(' ')[0])"  class="title" center>
                <span :style="{color:item.status==10?'#FE5E5E':'#3E7EFE'}">{{item.statusStr}}</span>
              </van-cell>
              <van-cell class="itemCont" :title="item.houseFullName"  :label="item.content" is-link center icon-prefix="widthImg" :icon="item.url?item.url:require('@/assets/img/flower.png')">
                <template #title>
                  <span class="custom-title contTitle">{{item.houseFullName}} <span>({{item.typeStr}})</span></span>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
import Vue from "vue";
export default {
  components :{
    topBar
  },
  data() {
    return {
      loading: false,
      finished: false,
      rangeShow: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      typeShow: false,
      moreShow: false,
      totalCount: 0,
      page: 0,
      limit: 10,
      scope: '',
      currentDate: '',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      typeList : ['1','2'],
      rangeList: [{ value: "0", label: "所有" }, { value: "1", label: "我提问的" }],
      statusList: [
        { label: '无法处理', value: '0', },
        { label: '未处理', value: '10' },
        { label: '处理中', value: '20' },
        { label: '处理完成', value: '30' },
        { label: '已评论', value: '40' }],
      moreStatusValue: { label: '', value: ''},
      currentType: '',
      moreTypeValue: '',
      moreType:'',
      searchValue: '',
      dataList: [],
      getWChatData: ''
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id,status) {
      let data = {'id':id,'status':status}
      this.$store.commit('setProblemData', data)
      // this.$router.push('/trifle-info?id=' + id + '&status=' + status)
      this.$router.push({path: '/trifle-info', query: {id, status}})
    },
    getDataList () {
      this.page++
      // this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/list'),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: this.page,          //当前页码
          limit: this.limit,        //每页展示的记录数
          name: this.searchValue, // 搜索框内容,以名字作为查询条件
          beginDate: this.selectDate.beginDate, //提问时间
          endDate: this.selectDate.endDate,
          scope: this.scope,
          type: this.currentType,          //问题类型
          status: this.moreStatusValue.value,      //处理状态
          source: '',
          solveTarget: '', //处理对象
          clientType: 2,
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          if (this.dataList.length == data.list.totalCount) {
            this.finished = true
          }
          data.list.list.forEach(item=> {
            if (item.problemFiles && item.problemFiles.length != 0) {
              let file = item.problemFiles[0]
              let url = file.filePath
              if(url){
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.totalCount = data.list.totalCount
          this.dataList = this.dataList.concat(data.list.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        // this.$toast.fail(data.msg);
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getProblemType () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId,
          code: 'problemType'
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.typeList = data.dicts
          this.typeList.unshift({label:'所有', value: ''})
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    rangeconfirm (val) {
      this.scope = val.value
      this.page = 0
      this.getDataList()
      this.rangeShow = false
    },
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    typeConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.currentType = value.value
      this.getDataList()
      this.typeShow = false
    },
    typeTagFn (val) {
      this.moreTypeValue = val.label
      this.moreType = val.value
    },
    moreCancel () {
      this.moreTypeValue = ''
      this.moreType = ''
      this.moreStatusValue.label = ''
      this.moreStatusValue.value = ''
      this.getDataList()
    },
    moreConfirm () {
      this.dataList = []
      this.page = 0
      this.currentType = this.moreType
      this.getDataList()
      this.moreShow = false
    },
    moreClose () {
      this.typeList.forEach(item => {
        if(this.currentType === item.value) {
          this.moreTypeValue = item.label
        }
      })
    },
    statusTagFn (item) {
      this.moreStatusValue.label = item.label
      this.moreStatusValue.value = item.value
    },
    goAdd () {
      this.$router.push('/trifle-add')
    },
    onSearch(val) {
      this.page = 0
      this.searchValue = val
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
  },
  created () {
    // this.$toast.loading({
    //     duration: 0,
    //     message: '加载中...',
    //     forbidClick: true,
    // });
    this.getDataList()
    this.getProblemType()
  }
}
</script>

<style>
.van-grid-item__content {
  padding: 10px  0;
}
</style>
<style lang="scss" scoped>
p {
  margin: 0;
}
.itemCont {
  .van-cell__label {
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow:ellipsis;
  whitewhite-space: nowrap;
  padding: 0 10px;
}
.contTitle {
  font-size: 30px;
  width: 420px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: #3D7EFE;
    font-size: 30px;
  }
}
.widthImg {
  height: 100%;
  img {
    width: 90px;
    height: 90px;
  }
}
.title {
  .van-cell__title {
    color: #999;
  }
  .van-cell__value {
    color: #FE5E5E;
  }
}

.addBtn {
  position: fixed;
  right: 40px;
  bottom: 130px;
  z-index: 999;
  width: 160px;
  height: 160px;
  img {
    width: 100%;
    height: 100%;
  }
}

.van-icon-ellipsis {
  line-height: 100px;
  vertical-align: middle;
  margin-left: 5px;
}
</style>
